<template>
  <div v-if="ready">
    <!-- <p class="text-center">Selected Date: {{ formattedDate }}</p> -->
    <calendar
      v-if="showCalendar"
      class="detail-calendar"
      v-model="curr"
      :detail="true"
      :userId="profile.userId"
      @modalEvent="modalEvent"
      @selectMurphy="selectMurphy"
    />
    <Card v-if="showCard" :payload="selectedMurphy" @close="closeCard" />
    <Modal v-if="modal.show" :payload="modal" @close="modal.show = false" />
  </div>
</template>

<script>
import Calendar from "@/components/calendar";
import Card from "@/components/card";
import Modal from "@/components/modal";
import ModalEvent from "@/mixins/modal";
import Util from "@/mixins/util";
const dateFns = require("date-fns");

export default {
  name: "Home",
  components: {
    Calendar,
    Card,
    Modal,
  },
  mixins: [ModalEvent, Util],
  data: function () {
    return {
      ready: false,
      liffId: "1656896964-xG5nnrYW",
      title: "",
      curr: new Date(),
      showCalendar: false,
      showCard: false,
      selectedMurphy: {},
    };
  },
  async created() {
    await this.setLiff(this.liffId);
    this.ready = true;
    this.showCalendar = true;
    // this.modalEvent("fb");
    // console.log(this.modal);
  },
  computed: {
    formattedDate() {
      return dateFns.format(this.curr, "MM/DD/YYYY");
    },
  },
  methods: {
    closeCard() {
      this.setTitle();
      this.showCard = false;
    },
    selectMurphy(data) {
      this.selectedMurphy = data;
      this.showCard = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-calendar {
  min-height: 100vh;
}
</style>
